import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    )
    {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('iconsmind', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        this._matIconRegistry.addSvgIcon('truck_bid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/truck_bid.svg'));
        this._matIconRegistry.addSvgIcon('pencil', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/pencil.svg'));
        this._matIconRegistry.addSvgIcon('truck_loaded', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/truck_loaded.svg'));
        this._matIconRegistry.addSvgIcon('brain', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/brain.svg'));
        this._matIconRegistry.addSvgIcon('carreta', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/carreta.svg'));
        this._matIconRegistry.addSvgIcon('rodotrem', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/rodotrem.svg'));
        this._matIconRegistry.addSvgIcon('cavalo', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/cavalo6x4.svg'));
        this._matIconRegistry.addSvgIcon('carreta_ls', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/carreta_ls.svg'));
        this._matIconRegistry.addSvgIcon('dolly', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/dolly.svg'));
        this._matIconRegistry.addSvgIcon('factory', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/factory.svg'));
    }
}
