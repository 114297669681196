/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { IPagination } from '../pagination/pagination.types';

@Injectable({
  providedIn: 'root'
})
export class LeilaoTransportadorService {

  public _auctionId = new BehaviorSubject<boolean|string>(false);
  public _auction: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  public _auctions: BehaviorSubject<any> = new BehaviorSubject([]);
  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

  private api = environment.api_order;
  private navigationName: string = 'menu-detail';

  set auction$(auct: any) { this._auction.next(auct); }
  get auction$(): Observable<any> { return this._auction.asObservable(); }
  set auctionId$(auctionId: any) { this._auctionId.next(auctionId); }
  get auctionId$(): Observable<any> { return this._auctionId.asObservable(); }
  get auctions$(): Observable<any> { return this._auctions.asObservable(); }
  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }

  constructor(
    private _http: HttpClient,
    public _navigationSrv: FuseNavigationService
  ) {}

  list(page: number = 0, size: number = 10, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'desc', search: string = ''):
  Observable<{ meta: IPagination; data: any[] }>
  {
    return this._http.get<{ meta: IPagination; data: any[] }>(this.api + '/auction/transportador', {
        params: { page: '' + page, size: '' + size, sort, order, search }
    }).pipe(
        tap((response) => {
            this._pagination.next(response.meta);
            this._auctions.next(response.data);
        })
    );
  }

  recovery(orderId): Observable<any>
  {
    if (orderId !== undefined && orderId) {
        return this._http.get(this.api + `/auction/transportador/${orderId}`)
        .pipe(
          tap((result: any) => {
            if (result.data) {
              sessionStorage.setItem('_auction_t', result.data);
              const dt = JSON.parse(atob(result.data));
              this._auction = dt;
            }
          }),
          map(() => true)
        );
    } else {
      return of(false);
    }
  }

  recoverySession(): any
  {
    if (sessionStorage.getItem('_auction_t') !== undefined) {
      const dt = JSON.parse(atob(sessionStorage.getItem('_auction_t')));
      this._auctionId.next(dt.id);
      return dt;
    }
    return false;
  }

  createBid(data: any): Observable<any>
  {
    const id = data.id;
    return this._http.post(this.api + `/bid/${id}`, data)
    .pipe(
      map(res => res)
    );
  }

  acceptCounterProposal(bidId: any): Observable<any>
  {
    return this._http.get(this.api + `/bid/counter-proposal/${bidId}`)
    .pipe(
      map(res => res)
    );
  }

  declineCounterProposal(bidId: any): Observable<any>
  {
    return this._http.get(this.api + `/bid/decline-counter-proposal/${bidId}`)
    .pipe(
      map(res => res)
    );
  }

  declineBid(dispatchOrderId: any): Observable<any>
  {
    return this._http.delete(this.api + `/bid/${dispatchOrderId}`)
    .pipe(
      map(res => res)
    );
  }

  linkDriver(drivers: any): Observable<boolean>
  {
    const order_id = this._auctionId.value;
    return of(false);

  }

  linkVehicles(vehicles: any): Observable<boolean>
  {
    const order_id = this._auctionId.value;
    return of(false);
  }

  getRanking(id: string): Observable<any>
  {
    return this._http.get(this.api + `/bid/transportadora/${id}`)
    .pipe(
      map((res: any) => res.data)
    );
  }

  // Cronometro
  getElapsedTime(expirated_date: any): any
  {
    const t = new Date(expirated_date);
    const dataReg = t.setSeconds(t.getSeconds());

    let totalSeconds = Math.floor((dataReg - new Date().getTime()) / 1000);
    if (totalSeconds < 0) {
      return false;
    }

    let hours = 0;
    let minutes = 0;
    const seconds = 0;
    if (totalSeconds > 0) {
      if (totalSeconds >= 3600) {
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds -= 3600 * hours;
      }

      if (totalSeconds >= 60) {
        minutes = Math.floor(totalSeconds / 60);
        totalSeconds -= 60 * minutes;
      }
    }

    const hora = (hours < 10)? '0' + hours : hours;
    const minuto = (minutes < 10)? '0' + minutes : minutes;
    const segundo = (totalSeconds < 10)? '0' + totalSeconds : totalSeconds;

    return {
      hours: hora,
      minutes: minuto,
      seconds: segundo
    };
  }

  toggleMenuFrete(disabled: boolean): void
  {
    const navComponent = this._navigationSrv.getComponent<FuseVerticalNavigationComponent>(this.navigationName);
    if ( !navComponent ) {return null;}
    navComponent.refresh();
    const navigation = navComponent.navigation;
    const itemDriver = this._navigationSrv.getItem('driver', navigation);
    const itemTracker = this._navigationSrv.getItem('tracker', navigation);

    itemDriver.disabled = disabled;
    itemTracker.disabled = disabled;

    if(disabled) {
      itemDriver.tooltip = 'Aguardando conclusão de leilão';
      itemTracker.tooltip = 'Aguardando conclusão de leilão';
    } else {
      itemDriver.tooltip = '';
      itemTracker.tooltip = '';
    }
    navComponent.refresh();
  }

}
