import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { NotificationService } from './core/notification/notification.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{

    ambient = environment.ambientserver;
    version = environment.version;
    constructor()
    {}

}
