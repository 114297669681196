import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateAccessService } from './authenticate-access.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessResolver implements Resolve<boolean> {

  constructor(
    private _authAccessSrv: AuthenticateAccessService
  ) {
  }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const token = route.queryParams._tk;
    const bearer = route.queryParams._auth;
    return this._authAccessSrv.authenticateAccess(token, bearer);
  }
}
