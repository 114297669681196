import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { BaseFunctionsComponent } from 'app/mock-api/common/basic-functions/base-functions';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IPagination } from '../pagination/pagination.types';

@Injectable({
  providedIn: 'root'
})
export class FreteService extends BaseFunctionsComponent
{

  private api = environment.api_order;
  private navigationName: string = 'menu-frete';

  public _orderId = new BehaviorSubject<boolean|string>(false);
  public _order: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  public _orders: BehaviorSubject<any | boolean> = new BehaviorSubject(false);

  set order$(order) { this._order.next(order); }
  get order$(): Observable<any> { return this._order.asObservable(); }

  set orderId$(orderId: any) { this._orderId.next(orderId); }
  get orderId$(): Observable<any> { return this._orderId.asObservable(); }

  get orders$(): Observable<any> { return this._orders.asObservable(); }

  public _hasOrder = new BehaviorSubject<boolean>(false);
  get hasOrder$(): Observable<any> { return this._hasOrder.asObservable(); }

  private _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);
  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }

  constructor( 
    private _http: HttpClient,
    public _navigationSrv: FuseNavigationService
  ) {
    super();
  }

  public listAll(page: number = 0, size: number = 10, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'desc', search: any = null)
  {
    const params = {
      params : {
        page: '' + page,
        size: '' + size,
        sort,
        order,
        search
      }
    }; 
    return this._http.get(this.api + '/order', params)
    .pipe(
      tap((result: any) => {
        if (result) {
          this._orders.next(result.data);
          const pagina = {
            length: result.meta.total,
            size: result.meta.per_page,
            page: result.meta.current_page,
            lastPage: result.meta.last_page,
            startIndex: 0,
            endIndex: result.meta.last_page
          };
          this._pagination.next(pagina);
        }
      }),
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  public recovery(id: string)
  {
    this.resetAll();
    return this._http.get(this.api + `/order/${id}`).pipe(
      tap((result: any) => {
        if (result.data) {
          this.setResultado(result);
          this._hasOrder.next(true);
        }
      }),
      map(res => res.data),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  public store(dados: any)
  {
    return this._http.post(this.api + `/order`, dados).pipe(
      tap((result: any) => (this._hasOrder.next(true), this.setResultado(result))),
      catchError((err: HttpErrorResponse) => {
        this.resetAll();
        return throwError(err);
      })
    );
  }

  public update(dados: any)
  {
    return this._http.put(this.api + `/order/${dados.id}`, dados).pipe(
      tap((result: any) => (this._hasOrder.next(true), this.setResultado(result))),
      catchError((err: HttpErrorResponse) => {
        this.resetAll();
        return throwError(err);
      })
    );
  }

  toggleMenuFrete(disabled: boolean)
  {
    const navComponent = this._navigationSrv.getComponent<FuseVerticalNavigationComponent>(this.navigationName);
    if ( !navComponent ) return null;
    const navigation = navComponent.navigation;
    const itemMercadoria = this._navigationSrv.getItem('mercadoria', navigation);
    itemMercadoria.disabled = disabled;
    if(disabled) {
      itemMercadoria.tooltip = 'Aguardando seleção do registro';
    } else {
      itemMercadoria.tooltip = '';
    }
    navComponent.refresh();
  }

  private setResultado(result)
  {
    sessionStorage.setItem('_order', btoa(JSON.stringify(result.data)));
    this._order.next(result.data);
    this._orderId.next(result.data.id);
    this.toggleMenuFrete(false);
  }

  public resetAll()
  {
    sessionStorage.removeItem('_order');
    this._order.next(false);
    this._orderId.next(false);
    this._hasOrder.next(false);
  }

}
