<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user?.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full bg-green-500"></span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="text-muted">{{user?.client.type}}</span>
            <span class="mt-1.5 text-gray-600 text-sm">{{user?.client.razao_social}}</span>
            <span class="mt-1.5 text-md font-medium">{{user?.name}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sair</span>
    </button>
</mat-menu>
