/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
    production: false,
    ambientserver: 'development',
    version: '1.8.0', // 05/09/2024
    url: 'https://hml.sbrcargo.com.br',
    api_auth: 'https://auth.cprdigital.com.br/backend/oauth',
    api: 'https://auth.cprdigital.com.br/backend/api',
    api_order: 'https://hml.sbrcargo.com.br/msorder/api',
    api_embarcadora: 'https://hml.sbrcargo.com.br/msembarcador/api',
    api_transportadora: 'https://hml.sbrcargo.com.br/mstransportador/api',
    api_motorista: 'https://hml.sbrcargo.com.br/msdriver/api',
    api_veiculo: 'https://hml.sbrcargo.com.br/msvehicle/api',
    api_contract: 'https://hml.sbrcargo.com.br/mscontract',
    api_report: 'https://hml.sbrcargo.com.br/msreport',
    api_driver: 'https://hml.sbrcargo.com.br/msdriver/api',
    api_fornecedor: 'http://localhost/msmines',
    api_mineradora: 'https://hml.sbrcargo.com.br/msmines',
    url_documents: 'https://sbrcargo.nyc3.digitaloceanspaces.com/',
    mapbox: { accessToken: 'pk.eyJ1IjoiYWxleGFuZHJlaGpvZGF4IiwiYSI6ImNrcGNxMGJnMjBjMzkyb3IyMjdvdmxtM2QifQ.wUPAtd4tXsfGvmse59m-iA'},
    client_id: '951103d1-ea10-4649-809d-90d76f5cf3c7',
    client_secret: 'EFGTadB5075yYtYpNfimPbY8ByOsUKq6pDRSMJiD',
    firebase: {
        apiKey: 'AIzaSyDDJsGLxSyo0hIATJK9RU3esvTn86v-CSI',
        authDomain: 'sbrcargo.firebaseapp.com',
        databaseURL: 'https://sbrcargo-default-rtdb.firebaseio.com',
        projectId: 'sbrcargo',
        storageBucket: 'sbrcargo.appspot.com',
        messagingSenderId: '502365096697',
        appId: '1:502365096697:web:94141fbc0150085c31f028',
    }
};
