import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MobileAuthService } from './mobile-auth.service';

@Injectable()
export class MobileAuthInterceptor implements HttpInterceptor {

    constructor(private _mobileAuthService: MobileAuthService)
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let newReq = req.clone();
        if (
            !req.url.includes('assets') && 
            !req.url.includes('oauth') && 
            !req.url.includes('viacep') && 
            !req.url.includes('nominatim') &&
            !req.url.includes('openstreetmap') &&
            !req.url.includes('s3') &&
            !req.url.includes('reset-password') &&
            !req.url.includes('mapbox'))
        {
            if ( this._mobileAuthService.accessToken )
            {
                newReq = req.clone({
                    headers: req.headers.set('Authorization', this._mobileAuthService.accessToken)
                });
            }
        }
        return next.handle(newReq).pipe(
            catchError((error) => {
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    this._mobileAuthService.signOut();
                    location.reload();
                }
                return throwError(error);
            })
        );

    }

}
