import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { AuthUtils } from '../auth/auth.utils';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private api = environment.api;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    constructor(private _httpClient: HttpClient){}

    set user(value: User)
    {
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    recoveryUserSession(): void
    {
        if (AuthUtils.getCookie('_us_ca') !== undefined)
        {
            const _us = AuthUtils.getCookie('_us_ca');
            const user = JSON.parse(atob(_us));
            this._user.next(user);
        }
    }

    get(): Observable<User>
    {
        const token = AuthUtils.getCookie('_atk_ca');
        const id = AuthUtils.getTokenUserIdentification(token);
        if (!id) {
            return throwError('User is not logged in.');
        }
        return this._httpClient.get<User>(this.api + `/user/${id}`).pipe(
            tap((res: any) => {
                const user = btoa(JSON.stringify(res.data));
                AuthUtils.setCookie({name: '_us_ca', value: user, session: true});
                this._user.next(res.data);
            })
        );
    }

    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
