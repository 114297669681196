import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { IPagination } from '../pagination/pagination.types';

@Injectable({
  providedIn: 'root'
})
export class DispatchFreightService
{
  public _freightId = new BehaviorSubject<boolean|string>(false);
  public _freight: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  public _freights: BehaviorSubject<any | boolean> = new BehaviorSubject(false);
  public _transporte: BehaviorSubject<any> = new BehaviorSubject(null);
  public _router: BehaviorSubject<any> = new BehaviorSubject(null);
  public _pagination: BehaviorSubject<IPagination | null> = new BehaviorSubject(null);

  set freight$(freight) { this._freight.next(freight); }
  get freight$(): Observable<any> { return this._freight.asObservable(); }

  set freightId$(freightId: any) { this._freightId.next(freightId); }
  get freightId$(): Observable<any> { return this._freightId.asObservable(); }

  get freights$(): Observable<any> { return this._freights.asObservable(); }
  get transporte$(): Observable<any> { return this._transporte.asObservable(); }
  get router$(): Observable<any> { return this._router.asObservable(); }

  get pagination$(): Observable<IPagination> { return this._pagination.asObservable(); }

  private api = environment.api_order;
  private navigationName: string = 'menu-dispatch-freight';

  constructor(
    private _http: HttpClient,
    public _navigationSrv: FuseNavigationService
  ) {}

  public listAll(page: number = 0, size: number = 10, sort: string = 'created_at', order: 'asc' | 'desc' | '' = 'desc', search: any = null): Observable<any>
  {
    const params = {
      params : {
        page: '' + page,
        size: '' + size,
        sort,
        order,
        search
      }
    };
    return this._http.get(this.api + '/dispatch-freight', params)
    .pipe(
      tap((result: any) => {
        if (result) {
          this._freights.next(result.data);
          const pagina = {
            length: result.meta.total,
            size: result.meta.per_page,
            page: result.meta.current_page,
            lastPage: result.meta.last_page,
            startIndex: 0,
            endIndex: result.meta.last_page
          };
          this._pagination.next(pagina);
        }
      }),
      map(res => res),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public recovery(id: string): Observable<any>
  {
    return this._http.get(this.api + `/dispatch-freight/${id}`).pipe(
      tap((result: any) => {
        if (result.data) {
          this.setResultado(result);
        }
      }),
      map(res => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public create(orders: any): Observable<any>
  {
    return this._http.post(this.api + '/dispatch-freight/create', orders).pipe(
      map((result: any) => result),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public store(dados: any): Observable<any>
  {
    return this._http.post(this.api + '/dispatch-freight', dados).pipe(
      tap((result: any) => this.setResultado(result)),
      catchError((err: HttpErrorResponse) => {
        this.resetAll();
        return throwError(err);
      })
    );
  }

  public update(dados: any): Observable<any>
  {
    return this._http.put(this.api + `/dispatch-freight/${dados.id}`, dados).pipe(
      tap((result: any) => this.setResultado(result)),
      catchError((err: HttpErrorResponse) => {
        this.resetAll();
        return throwError(err);
      })
    );
  }

  public updateDestinationPositions(destinations: any): Observable<any>
  {
    return this._http.post(this.api + '/dispatch-freight/destinations', {destinations});
  }

  public transferOrder(orderId: any): Observable<any>
  {
    return this._http.post(this.api + `/dispatch-freight/transfer/${orderId}`, {}).pipe(
      map((result: any) => result),
      catchError((err: HttpErrorResponse) => {
        this.resetAll();
        return throwError(err);
      })
    );
  }

  public delete(): Observable<any>
  {
    const dispatchOrderId = this._freightId.value;
    if (dispatchOrderId !== undefined) {
      return this._http.delete(this.api + `/dispatch-freight/${dispatchOrderId}`).pipe(
        tap((result: any) => this.setResultado(result)),
        catchError((err: HttpErrorResponse) => {
          this.resetAll();
          return throwError(err);
        })
      );
    }
  }

  public resetAll(): void
  {
    sessionStorage.removeItem('_dispatchorder');
    this._freight.next(false);
    this._freightId.next(false);
  }

  toggleMenuFrete(disabled: boolean): any
  {
    const navComponent = this._navigationSrv.getComponent<FuseVerticalNavigationComponent>(this.navigationName);
    if ( !navComponent ) {return null;}
    const navigation = navComponent.navigation;
    const itemEmbarque = this._navigationSrv.getItem('embarque', navigation);
    const itemTransp = this._navigationSrv.getItem('transportadora', navigation);
    const itemRoteiro = this._navigationSrv.getItem('roteiro', navigation);

    itemEmbarque.hidden = (): boolean => disabled;
    itemTransp.hidden = (): boolean => disabled;
    itemRoteiro.hidden = (): boolean => disabled;

    navComponent.refresh();
  }

  /*********************** TRANSPORTADORAS *********************************/

  public recoveryTransporte(id: string): Observable<any>
  {
    return this._http.get(this.api + `/transportadora/${id}`).pipe(
      tap((result: any) => {
        if (result.data) {
          this._transporte.next(result.data);
        }
      }),
      map(res => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeTransporte(dados: any, orderId: string | null): Observable<any>
  {
    if (orderId !== undefined) {
      return this._http.post(this.api + `/transportadora/${orderId}`, dados).pipe(
        tap((result: any) => this._transporte.next(result.data)),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
    }
  }

  public updateTransporte(dados: any): Observable<any>
  {
      return this._http.put(this.api + `/transportadora/${dados.id}`, dados).pipe(
        tap((result: any) => this._transporte.next(result.data)),
        catchError((err: HttpErrorResponse) => throwError(err))
      );

  }
  /************************* ROTERIZAÇÃO ***********************************/

  public verifyExistSessionRoute(): void
  {
    if (sessionStorage.getItem('_router') !== undefined && sessionStorage.getItem('_router') !== null) {
      const rota: any = JSON.parse(sessionStorage.getItem('_router'));
      this._router.next(rota);
    } else {
      const freightId: any = this._freightId.value;
      if (freightId !== undefined) {
        this.recoveryFreight(freightId).subscribe();
      } else {
        this._router.next(false);
      }
    }
  }

  public recoveryFreight(id: string): Observable<any>
  {
    return this._http.get(this.api + `/router/${id}`).pipe(
      tap((result: any) => {
        if (result.data) {this._setRouteResult(result.data);}
      }),
      map((res: any) => res.data),
      catchError((err: HttpErrorResponse) => throwError(err))
    );
  }

  public storeFreightAndRouter(dados: any, orderId: string | null): Observable<any>
  {
    if (orderId !== undefined) {
      return this._http.post(this.api + `/router/${orderId}`, dados).pipe(
        tap((result: any) => {
          if (result.data) {this._setRouteResult(result.data);}
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
    }
  }

  public updateFreightAndRouter(dados: any): Observable<any>
  {
      return this._http.put(this.api + `/router/${dados.id}`, dados).pipe(
        tap((result: any) => {
          if (result.data) {this._setRouteResult(result.data);}
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );

  }

  private setResultado(result): void
  {
    sessionStorage.setItem('_dispatchorder', JSON.stringify(result.data));
    this._freight.next(result.data);
    this._freightId.next(result.data.id);
  }

  private _setRouteResult(data: any): void
  {
    sessionStorage.setItem('_router', JSON.stringify(data));
    this._router.next(data);
  }
}
