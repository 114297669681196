import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MobileAuthService {

  private _authenticated: boolean = false;
  private api = environment.api_order;

  set accessToken(token: string)
  {
    sessionStorage.setItem('mob_acc_tk', token);
  }

  get accessToken(): string
  {
      return (sessionStorage.getItem('mob_acc_tk') && sessionStorage.getItem('mob_acc_tk') !== 'undefined')? sessionStorage.getItem('mob_acc_tk') : '';
  }

  constructor(private _httpClient: HttpClient) { }

  signIn(credentials: { cpf: string; token: string }): Observable<any>
  {
      if ( this._authenticated ) {
          return throwError('Usuário já está logado');
      }

      const params = {
          'cpf' : credentials.cpf,
          'token' : credentials.token,
      };
      return this._httpClient.post(this.api + '/mobile/auth', params).pipe(
          switchMap((response: any) => {
              this.accessToken = response.access_token;
              this._authenticated = true;
              return of(response);
          })
      );
  }

  signOut(): Observable<any>
  {
      // Remove the access token from the local storage
      sessionStorage.removeItem('mob_acc_tk');
      this._authenticated = false;
      return of(true);
  }

  check(): Observable<boolean>
  {
      if ( this._authenticated )
      {
          return of(true);
      }

      // Check the access token availability
      if ( !this.accessToken ||  this.accessToken === 'undefined')
      {
          return of(false);
      }

      // If the access token exists and it didn't expire, sign in using it
      return of(true);
  }

}
