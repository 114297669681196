import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MobileAuthService } from './mobile-auth.service';
import { MobileAuthInterceptor } from './mobile-auth.interceptor';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    MobileAuthService,
    {
        provide : HTTP_INTERCEPTORS,
        useClass: MobileAuthInterceptor,
        multi   : true
    }
]
})
export class MobileAuthModule { }
