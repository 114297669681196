<button mat-icon-button (click)="openPanel()" #sbrmodulosOrigin>
    <mat-icon [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
</button>

<ng-template #sbrmodulosPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
        <!-- HEADER -->
        <div class="flex flex-shrink-0 items-center p-2 pl-4 bg-gray-600 text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="w-full flex justify-between items-center text-lg font-medium leading-10">
                <span>Modulos SBR PRIME</span>
                <mat-spinner diameter="24" *ngIf="showLoader"></mat-spinner>
            </div>
        </div>
        <!-- BODY -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 -mb-px overflow-y-auto bg-card">
            <ng-container>
                <!-- Shortcuts -->
                <div class="grid grid-cols-2 grid-flow-row">
                    <!-- Shortcut -->
                    <ng-container *ngFor="let shortcut of sbrModulos; trackBy: trackByFn">
                        <div class="relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                            <!-- Normal links -->
                            <a class="flex flex-col items-center justify-center w-full h-full py-6 no-underline cursor-pointer" (click)="redirectToModulo(shortcut)" [ngClass]="{'pointer-events-none': shortcut === 'allowed-sbrcargo-hom'}">
                                <ng-container *ngTemplateOutlet="linkContent"></ng-container>
                            </a>
                            <!-- Link content template -->
                            <ng-template #linkContent>
                                <div class="relative flex flex-shrink-0 items-center justify-center w-12 h-12 mb-3 rounded-full bg-gray-100 dark:bg-gray-700">
                                    <img class="z-10 h-10" src="/assets/images/logo/sbrcargo_120.svg" *ngIf="shortcut === 'allowed-sbrcargo-hom'"/>
                                    <img class="z-10 h-10" src="/assets/images/logo/cpr.svg" *ngIf="shortcut === 'allowed-cpr-hom'"/>
                                    <img class="z-10 h-10" src="/assets/images/logo/pickingup-green.svg" *ngIf="shortcut === 'allowed-pickingup-hom'"/>
                                    <img class="z-10 h-10" src="/assets/images/logo/seed-green.svg" *ngIf="shortcut === 'allowed-seedcontrol-hom'"/>
                                </div>
                                <div class="font-medium text-center" *ngIf="shortcut === 'allowed-sbrcargo-hom'">SBRCARGO</div>
                                <div class="font-medium text-center" *ngIf="shortcut === 'allowed-cpr-hom'">CPR DIGITAL</div>
                                <div class="font-medium text-center" *ngIf="shortcut === 'allowed-pickingup-hom'">PICKING UP</div>
                                <div class="font-medium text-center" *ngIf="shortcut === 'allowed-seedcontrol-hom'">SEED CONTROL</div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="!sbrModulos || !sbrModulos.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                        <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                            <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
                        </div>
                        <div class="mt-5 text-2xl font-semibold tracking-tight">Módulos SBRPRIME</div>
                        <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">No momento, você não tem acesso aos outros módulos da SBRPRIME</div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>