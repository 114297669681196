/* eslint-disable @typescript-eslint/naming-convention */
import { HttpHeaders } from '@angular/common/http';
import { Component, SimpleChanges } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FuseAlertType } from '@fuse/components/alert';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { environment } from 'environments/environment';
// import Swal from 'sweetalert2';

@Component({
  template: ''
})
export class BaseFunctionsComponent {

  protected secret_key = 'NOT_APPLY';

  constructor() {
  }

  public formatarErroValidacao(err: any): any
  {
    let msg = '<strong>Ocorreu um problema. Confira abaixo as notificações</strong>';
    msg += '<ul class="list-group text-left">';
    Object.keys(err.error.errors).forEach((campo: string) => {
      msg += '<li class="list-group-item"><strong class="text-danger">' + campo + '</strong><ul>';
      err.error.errors[campo].forEach((er: any) => {
        msg += '<li class="text-danger"> ' + er + '</li>';
      });
      msg += '</ul></li>';
    });
    msg += '</ul>';
    return msg;
  }

  public formatError400(err: any): any
  {
    let msg = '<strong class="font-semibold text-red-900">Ocorreu um problema. Confira abaixo as notificações</strong>';
    msg += '<ul class="list-group text-left">';
    (err.error.message).map((item: string) => {
      msg += '<li class="text-red-600"> ' + item + '</li>';
    });
    msg += '</ul>';
    return msg;
  }

  public fuseAlert(
    fuseConfirmation: FuseConfirmationService,
    title: string,
    text: string,
    icon: string = 'mat_outline:warning_amber',
    color: any = 'warning'): any
  {
      return fuseConfirmation.open({
        title: `<strong>${title}</strong>`,
        message: text,
        icon: { show: true, name: icon, color: color },
        actions: {
          confirm: { show: false},
          cancel: { show: true, label: 'Fechar'}
        }
      });
  }

  public fuseConfirmation(
    fuseConfirmation: FuseConfirmationService,
    title: string,
    text: string,
    icon: string = 'mat_outline:warning_amber',
    color: any = 'warning'): any
  {
    return fuseConfirmation.open({
      title: `<strong>${title}</strong>`,
      message: text,
      icon: { show: true, name: icon, color: color },
      actions: {
        confirm: { show: true, label: 'SIM, CONFIRMO', color: 'primary' },
        cancel: { show: true, label: 'NÃO, CANCELAR' }
      }
    });
  }

  public setarInputError(form: FormGroup, fields = []): any
  {
    Object.keys(form.controls).forEach((campo) => {
      if (fields.includes(campo)) {
        if (form.controls[campo] instanceof FormGroup) {
          Object.keys(form.controls[campo]['controls']).forEach((subCampo) => {
            const ctrl = form.controls[campo]['controls'][subCampo];
            ctrl.markAsDirty();
            ctrl.markAsTouched();
          });
        }
        if (form.controls[campo] instanceof FormArray) {
          const total = form.controls[campo]['controls'].length;
          for (let idx = 0; idx < total; idx++) {
            Object.keys(form.controls[campo]['controls'][idx]['controls']).forEach((subCampo) => {
              const ctrl = form.controls[campo]['controls'][idx]['controls'][subCampo];
              ctrl.markAsDirty();
              ctrl.markAsTouched();
            });
          }
        }
      } else {
        const ctrl = form.controls[campo];
        ctrl.markAsDirty();
        ctrl.markAsTouched();
      }
    });
  }

  public setarInputErrorForArray(form: FormArray): any
  {
    Object.keys(form.controls).forEach((idx) => {
      const ctrl = form.controls[idx]['controls'];
      Object.keys(ctrl).forEach((campo) => {
        const field = ctrl[campo];
        field.markAsDirty();
        field.markAsTouched();
      });
    });
  }

  public validatorsFields(fields: string[], required: boolean, form: FormGroup): any
  {
    if (required) {
      fields.map(campos => form.controls[campos].setValidators([Validators.required]));
    } else {
      fields.map(campos => form.controls[campos].clearValidators());
    }
    form.updateValueAndValidity();
  }

  public addRemoveControl(action: string, fields: string[], form: FormGroup, required: boolean = false): any
  {
    if (action === 'ADD') {
      fields.map(campos => form.addControl(campos, new FormControl(null)));
    } else if(action === 'REMOVE') {
      fields.map(campos => form.removeControl(campos));
    }
    form.updateValueAndValidity();
    if (required) {
      this.validatorsFields(fields, true, form);
    }
  }

  public revalidarFormulario(changes: SimpleChanges, form: FormGroup): any
  {
    if (changes.revalidate && !changes.revalidate.firstChange && changes.revalidate.currentValue !== undefined) {
      if (changes.revalidate.currentValue) {
        this.setarInputError(form);
      }
    }
  }

  public resetarFormulario(changes: SimpleChanges, form: FormGroup): any
  {
    if (changes.clear && !changes.clear.firstChange && changes.clear.currentValue  !== undefined) {
      if (changes.clear.currentValue) {
        this.limparFormulario(form);
        form.setErrors(null);
        form.updateValueAndValidity();
      }
    }
  }

  public limparFormulario(form: FormGroup, cvas: boolean | Array<string> = false): any
  {
    Object.keys(form.controls).forEach((campo) => {
      const ctrl = form.controls[campo];
      if (cvas && typeof cvas == 'object') {
        if (cvas.indexOf(campo) > -1) {
          if (ctrl instanceof FormArray) {
            ctrl.clear();
          } else {
            ctrl.setValue(null);
          }
        } else {
          ctrl.reset();
          ctrl.markAsPristine();
          ctrl.markAsUntouched();
        }
      } else {
        if (ctrl !== undefined) {
          ctrl.reset();
          ctrl.markAsPristine();
          ctrl.markAsUntouched();
        }
      }
    });
  }

  public dialogConfirmation(description: string): any
  {
    // const { value: confirm } = await Swal.fire({
    //   icon: "question",
    //   title: description,
    //   showCancelButton: true,
    //   showConfirmButton: true,
    //   cancelButtonText: "NÃO, CANCELAR",
    //   confirmButtonText: "SIM, CONFIRMO"
    // });
    // return confirm;
    return true;
  }

  protected _error422(err: any, justWarning: boolean = false): any
  {
    if (err.status === 422) {
      return this.formatarErroValidacao(err);
    } else {
      let msg = '';
      if (justWarning) {
        msg = '<div class="text-warning">' + err.error.message + '</div>';
      } else {
        msg += '<strong>Ocorreu um erro</strong>';
        msg += '<div class="text-danger">' + err.error.message + '</div>';
      }
      return msg;
    }
  }

  protected _alertError422(err: any, justWarning: boolean = false): {type: FuseAlertType; message: string}
  {
    if (err.status === 422) {
      const msg =  this.formatarErroValidacao(err);
      return {type: 'error', message: msg};
    } else {
      let msg = '';
      if (justWarning) {
        msg += '<div class="text-warning">' + err.error.message + '</div>';
        return {type: 'info', message: msg};
      } else {
        msg += '<strong>Ocorreu um erro</strong>';
        msg += '<div class="text-danger">' + err.error.message + '</div>';
        return {type: 'error', message: msg};
      }
    }
  }

  protected _unauthorization(err: any): any
  {
    if (err.status === 401) {
      // Swal.fire({icon: 'error', title: 'ATENÇÃO', html: err.error.message});
      return true;
    }
    return false;
  }

  protected _createdHeader(token: string | boolean): HttpHeaders
  {
    if (typeof token == 'string') {
      return new HttpHeaders({
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'X-secret': this.secret_key,
        'Authorization': token
      });
    } else {
      return new HttpHeaders({
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'X-secret': this.secret_key
      });
    }
  }

}
