/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthenticateAccessGuard } from './modules/auth/authenticate-access/authenticate-access.guard';
import { AuthenticateAccessResolver } from './modules/auth/authenticate-access/authenticate-access.resolver';
import { PermissionGuard } from './core/auth/guards/permission.guard';
import { RoleTransportadorGuard } from './core/auth/guards/role-transportador.guard';
import { RoleEmbarcadorGuard } from './core/auth/guards/role-embarcador.guard';
import { TravelsGuard } from './modules/pages/travels/travels.guard';
import { AuthAdminGuard } from './core/auth/guards/auth-admin.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'signed-in-redirect-motorista', pathMatch: 'full', redirectTo: 'motorista/grid' },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },
    {
        path: 'authenticate',
        canActivate: [AuthenticateAccessGuard],
        resolve: { initialData: AuthenticateAccessResolver },
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/auth/authenticate-access/authenticate-access.module').then(m => m.AuthenticateAccessModule) },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
        ]
    },
    {
        path: 'motorista',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            { path: '', loadChildren: () => import('app/modules/pages/mobile/mobile.module').then(m => m.MobileModule) },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, RoleEmbarcadorGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/pages/embarcador-dashboard/embarcador-dashboard.module').then(m => m.EmbarcadorDashboardModule) },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, RoleTransportadorGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'carrier-grid', loadChildren: () => import('app/modules/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, TravelsGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'waypoint-grid', loadChildren: () => import('app/modules/pages/travels/travels.module').then(m => m.TravelsModule) },
        ]
    },
    {
        path: 'cadastro',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            { path: 'usuario', loadChildren: () => import('app/modules/pages/cadastros/usuarios/cadastro-usuarios.module').then(m => m.CadastroUsuariosModule) },
            { path: 'embarcadora', canActivate: [AuthAdminGuard], loadChildren: () => import('app/modules/pages/cadastros/embarcadora/embarcadora.module').then(m => m.EmbarcadoraModule) },
            { path: 'fornecedor', loadChildren: () => import('app/modules/pages/cadastros/fornecedores/fornecedor.module').then(m => m.FornecedorModule) },
             { path: 'usina', loadChildren: () => import('app/modules/pages/cadastros/mineradora/usinas/usina.module').then(m => m.UsinaModule) },
             { path: 'mineradora', loadChildren: () => import('app/modules/pages/cadastros/mineradora/mina/mineradora.module').then(m => m.MineradoraModule) },
            // // Add paths to mine register
            // { path: 'motorista-mina', loadChildren: () => import('app/modules/pages/cadastros/mineradora/motoristas/motorista.module').then(m => m.MotoristaMinerModule) },
            // { path: 'caminhao', loadChildren: () => import('app/modules/pages/cadastros/mineradora/caminhao/caminhao.module').then(m => m.CaminhaoModule) },
            // { path: 'pacarregadeira', loadChildren: () => import('app/modules/pages/cadastros/mineradora/pa-carregadeira/pa.module').then(m => m.PacarregadeiraModule) },
            // // end mine paths
            { path: 'embarcadora-regional', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-regional/embarcadora-regional.module').then(m => m.EmbarcadoraRegionalModule) },
            { path: 'embarcadora-filial', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-filial/embarcadora-filial.module').then(m => m.EmbarcadoraFilialModule) },
            { path: 'embarcadora-fazenda', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-fazenda/embarcadora-fazenda.module').then(m => m.EmbarcadoraFazendaModule) },
            { path: 'destinatario', loadChildren: () => import('app/modules/pages/cadastros/destinatarios/destinatarios.module').then(m => m.DestinatariosModule) },
            { path: 'transportadora', loadChildren: () => import('app/modules/pages/cadastros/transportadora/transportadora.module').then(m => m.TransportadoraModule) },
            { path: 'transportadora-virtual', loadChildren: () => import('app/modules/pages/cadastros/transportadora_virtual/transportadora-virtual.module').then(m => m.TransportadoraVirtualModule) },
            { path: 'motorista', loadChildren: () => import('app/modules/pages/cadastros/motorista/motorista.module').then(m => m.MotoristaModule) },
            { path: 'veiculo', loadChildren: () => import('app/modules/pages/cadastros/veiculos/veiculo.module').then(m => m.VeiculoModule) },
            { path: 'tabela-frete', loadChildren: () => import('app/modules/pages/cadastros/tabela-frete/tabela-frete.module').then(m => m.TabelaFreteModule) },
            { path: 'contrato-de-frete', loadChildren: () => import('app/modules/pages/contract/contract.module').then(m => m.ContractModule) },
        ]
    },
    {
        path: 'order',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'lote', loadChildren: () => import('app/modules/pages/solicitacao/master-order/master-order.module').then(m => m.MasterOrderModule) },
            { path: 'frete', loadChildren: () => import('app/modules/pages/solicitacao/frete/frete.module').then(m => m.FreteModule) },
            { path: 'dispatch-freight', loadChildren: () => import('app/modules/pages/solicitacao/dispatch-freight/dispatch-freight.module').then(m => m.DispatchFreightModule) },
            { path: 'dashboard', loadChildren: () => import('app/modules/pages/acompanhamento/view-frete/view-frete.module').then(m => m.ViewFreteModule) },
        ]
    },
    {
        path: 'processing',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'nfe', loadChildren: () => import('app/modules/pages/process-nfe/process-nfe.module').then(m => m.ProcessNfeModule) },
            { path: 'sheet', loadChildren: () => import('app/modules/pages/process-sheet-return/process-sheet-return.module').then(m => m.ProcessSheetReturnModule) },
        ]
    },
    {
        path: 'leilao',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'transportador', loadChildren: () => import('app/modules/pages/transportador/leilao/leilao-transportador.module').then(m => m.LeilaoTransportadorModule) },
            { path: 'embarcador', loadChildren: () => import('app/modules/pages/embarcador/leilao/leilao-embarcador.module').then(m => m.LeilaoEmbarcadorModule) },
        ]
    },
    {
        path: 'quotes',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'multiple-quotes', loadChildren: () => import('app/modules/pages/multiple-quotes/multiple-quotes.module').then(m => m.MultipleQuotesModule) },
        ]
    },
    {
        path: 'controls',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'portaria', loadChildren: () => import('app/modules/pages/portaria/portaria.module').then(m => m.PortariaModule) },
        ]
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, AuthAdminGuard],
        component: LayoutComponent,
        // resolve    : {
        //     initialData: InitialDataResolver,
        // },
        // children   : [
        //     {path: 'dashboard', loadChildren: () => import('app/modules/pages/embarcador-dashboard/embarcador-dashboard.module').then(m => m.EmbarcadorDashboardModule)},
        // ]
    },
    {
        path: 'policy',
        component  : LayoutComponent,
        data: { layout: 'empty'},
        children   : [
            {path: '', loadChildren: () => import('app/modules/landing/politica/politica.module').then(m => m.PoliticaModule)},
        ]
    },
    {
        path: 'terms',
        component  : LayoutComponent,
        data: { layout: 'empty'},
        children   : [
            {path: '', loadChildren: () => import('app/modules/landing/termos/termos.module').then(m => m.TermosModule)},
        ]
    },
];
